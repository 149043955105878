<template>
  <div v-if="get_post_detail" class="container my-5">
    <h1 class="text-center fw-bold" v-if="get_post_detail.title">
      {{ get_post_detail.title.rendered }}
    </h1>
    <p class="text-center">
      {{ get_post_detail.date | moment("DD/MM/YYYY") }}
    </p>
    <!-- <div class="d-flex justify-content-center">
      <img
        v-if="get_post_detail.attributes.avatar.data"
        class="mb-5 mt-3 img-fluid"
        :src="
          url_root + get_post_detail.attributes.avatar.data.attributes.url
        "
      />
    </div> -->
    <div class="content">
      <div v-html="get_post_detail.content.rendered"></div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import config from "../../config";
export default {
  components: {},
  data() {
    return {
      id_blog: this.$route.params.id,
      url_root: config.url_root_backend,
    };
  },
  computed: {
    ...mapGetters(["get_post_detail"]),
  },
  methods: {
    ...mapActions(["api_get_post_detail"]),
    changeUrlImg(data) {
      const IMG_REGEX = /\/uploads\//g;
      return data.replace(IMG_REGEX, this.url_root + "/uploads/");
    },
  },
  mounted: function () {
    this.api_get_post_detail(this.id_blog);
    window.scrollTo(0, 0);
  },
};
</script>