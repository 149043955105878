import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex);

import PostStore from './modules/post';

export default new Vuex.Store({
    modules: {
        PostStore: PostStore
    }
});