import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PostView from '../views/PostView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: HomeView,
    meta: {
      title: 'BitDigi-Tiên phong Chuyển Đổi Số',
    }
  },
  //========================router blog=================
  {
    path: '/post',
    name: 'Post',
    component: PostView,
    meta: {
      title: 'Bài viết',
    }
  },
  {
    path: '/post/:id',
    name: 'PostDetail',
    component: PostView,
    meta: {
      title: 'Chi tiết bài viết',
    }
  }
  //====================================================
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
