import config from "../../config";
import axios from "axios";

const PostStore = {
  //lưu trữ
  state: {
    posts: {},
    post_detail: {},
  },
  //lấy ra
  getters: {
    list_post: (state) => state.posts,
    get_post_detail: (state) => state.post_detail,
  },
  //đẩy vào lưu trữ
  mutations: {
    push_posts(state, dataResponse) {
      state.posts = dataResponse;
    },
    push_post_detail(state, dataResponse) {
      state.post_detail = dataResponse;
    },
  },
  //các hành động lấy dữ liệu
  //Lấy 3 bài viết mới nhất cho phần PostSection
  actions: {
    //Danh sách bài viết
    async api_get_three_post({ commit }) {
      try {
        const response = await axios.get(config.post_api.get_three_post);
        const posts = response.data;

        // Tạo các lời gọi API bổ sung để lấy hình đại diện
        const postsWithImages = await Promise.all(
          posts.map(async (post) => {
            if (post.featured_media) {
              // Gọi API lấy thông tin hình đại diện từ featured_media
              const mediaResponse = await axios.get(`https://cms.bitdigi.vn/wp-json/wp/v2/media/${post.featured_media}`);
              post.featured_image = mediaResponse.data.source_url; // Thêm đường dẫn hình ảnh vào đối tượng posts
            }
            return post;
          })
        );
        commit("push_posts", postsWithImages);
      } catch (error) {
        console.error("Lỗi khi lấy danh sách bài viết:", error);
      }
    },

    //Danh sách bài viết
    async api_get_all_post({ commit }) {
      try {
        const response = await axios.get(config.post_api.get_all_post);
        const posts = response.data;

        // Tạo các lời gọi API bổ sung để lấy hình đại diện
        const postsWithImages = await Promise.all(
          posts.map(async (post) => {
            if (post.featured_media) {
              // Gọi API lấy thông tin hình đại diện từ featured_media
              const mediaResponse = await axios.get(`https://cms.bitdigi.vn/wp-json/wp/v2/media/${post.featured_media}`);
              post.featured_image = mediaResponse.data.source_url; // Thêm đường dẫn hình ảnh vào đối tượng posts
            }
            return post;
          })
        );
        commit("push_posts", postsWithImages);
      } catch (error) {
        console.error("Lỗi khi lấy danh sách bài viết:", error);
      }
    },

    //Chi tiết bài viết
    async api_get_post_detail({ commit }, id) {
      try {
        const response = await axios.get(config.post_api.get_post_detail+id);
        // this.list_post = response.data;
        commit("push_post_detail", response.data);
      } catch (error) {
        console.error("Lỗi khi lấy chi tiết bài viết:", error);
      }
    },

  },
};

export default PostStore;
