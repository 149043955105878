<template>
  <div class="blog">
    <transition>
      <PostDetail v-if="$route.params.id"></PostDetail>
    </transition>
    <transition>
      <PostList v-if="$route.params.id == null"></PostList>
    </transition>
  </div>
</template>
<script>
import PostDetail from "../components/post/PostDetailComponent.vue";
import PostList from "../components/post/PostListComponent.vue";
export default {
  components: {
    PostDetail,
    PostList,
  },
};
</script>
<style>
.f-color-back {
  color: #1b1c2b;
}
</style>