<template>
  <div class="container py-3">
    <div class="row py-5">
      <div class="col-md-3 px-3">
        <h2 class="fs-2 fw-bold text-center text-md-start">
          Câu hỏi thường gặp
        </h2>
      </div>
      <div class="col-md-9">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2
              v-on:click="btn1 = !btn1"
              class="accordion-header"
              id="flush-headingOne"
            >
              <button class="accordion-button collapsed" type="button">
                <span class="fs-3 title me-3">01</span>Hiệu quả mạng lại khi áp
                dụng các giải pháp của BitDigi
              </button>
            </h2>
            <transition name="fade">
              <div
                id="flush-collapseOne"
                class="accordion-collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
                v-if="btn1"
              >
                <div class="accordion-body">
                  Tính thuận tiện.<br />
                  Chi phí khởi điểm thấp.<br />
                  Tiếp cận nhanh chóng, rộng và sâu hơn. <br />
                  Khả năng đo lường hiệu quả Nhắm đúng khách hàng mục tiêu.
                  <br />Xây dựng mối quan hệ Tương tác hai hay nhiều chiều.<br />
                  Tính lan truyền nhanh.
                </div>
              </div>
            </transition>
          </div>
          <div class="accordion-item">
            <h2
              v-on:click="btn2 = !btn2"
              class="accordion-header"
              id="flush-headingTwo"
            >
              <button class="accordion-button collapsed">
                <span class="fs-3 me-3 title">02</span>Digital Marketing là gì?
              </button>
            </h2>
            <transition name="fade">
              <div
                id="flush-collapseTwo"
                class="accordion-collapse"
                v-if="btn2"
              >
                <div class="accordion-body">
                  Digital Marketing là các hoạt động quảng bá cho sản
                  phẩm/thương hiệu nhằm tác động đến nhận thức khách hàng, kích
                  thích hành vi mua hàng của họ. Nói một cách dễ hiểu hơn,
                  Digital Marketing là các hoạt động tiếp thị sử dụng một hoặc
                  nhiều phương tiện kỹ thuật số trên Internet, nền tảng chủ yếu
                  của nó chính là Internet va kỹ thuật số.
                </div>
              </div>
            </transition>
          </div>
          <div class="accordion-item">
            <h2
              v-on:click="btn3 = !btn3"
              class="accordion-header"
              id="flush-headingThree"
            >
              <button class="accordion-button collapsed">
                <span class="fs-3 me-3 title">03</span>Digital Marketing có phải
                là Online Marketing hay không?
              </button>
            </h2>
            <transition name="fade">
              <div
                id="flush-collapseThree"
                class="accordion-collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
                v-if="btn3"
              >
                <div class="accordion-body">
                  Hiện nay, có rất nhiều người nhầm lẫn khái niệm Digital
                  Marketing và Online Marketing là một, tuy nhiên đây là một
                  quan điểm sai lầm bởi vì Digital Marketing và Online Marketing
                  là khác nhau, và Online Marketing cũng là một phần của Digital
                  Marketing.
                  <br />
                  Điểm khác nhau lớn nhất của Digital Marketing và Online
                  Marketing đó chính là nền tảng tạo nên chúng. Online Marketing
                  chỉ đơn thuần sử dụng Internet làm nền tảng chính và câc mạng
                  xã hội như Facebook, Instagram,.. là cốt yếu, còn của Digital
                  Marketing là kết hợp giữa các phương tiện truyền thông
                  Internet và các phương tiện kỹ thuật số.
                </div>
              </div>
            </transition>
          </div>
          <div class="accordion-item">
            <h2
              v-on:click="btn4 = !btn4"
              class="accordion-header"
              id="flush-headingThree"
            >
              <button class="accordion-button collapsed">
                <span class="fs-3 me-3 title">04</span>Giải pháp Multi-channel
                và Omni-Channel là gì?
              </button>
            </h2>
            <transition name="fade">
              <div
                id="flush-collapseThree"
                class="accordion-collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
                v-if="btn4"
              >
                <div class="accordion-body">
                  Multichannel: Tất cả các kênh có sẵn cho khách hàng, nhưng
                  không có sự kết nối đồng bộ.
                  <br />
                  Omni-channel: Tất cả các kênh có sẵn và được kết nối đồng bộ
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      btn1: false,
      btn2: false,
      btn3: false,
      btn4: false,
      btn5: false,
      btn6: false,
    };
  },
  watch: {
    btn1: function () {
      if (this.btn1) {
        this.btn2 = false;
        this.btn3 = false;
        this.btn4 = false;
        this.btn5 = false;
        this.btn6 = false;
      }
    },
    btn2: function () {
      if (this.btn2) {
        this.btn1 = false;
        this.btn3 = false;
        this.btn4 = false;
        this.btn5 = false;
        this.btn6 = false;
      }
    },
    btn3: function () {
      if (this.btn3) {
        this.btn1 = false;
        this.btn2 = false;
        this.btn4 = false;
        this.btn5 = false;
        this.btn6 = false;
      }
    },
    btn4: function () {
      if (this.btn4) {
        this.btn1 = false;
        this.btn2 = false;
        this.btn3 = false;
        this.btn5 = false;
        this.btn6 = false;
      }
    },
    btn5: function () {
      if (this.btn5) {
        this.btn1 = false;
        this.btn2 = false;
        this.btn3 = false;
        this.btn4 = false;
        this.btn6 = false;
      }
    },
    btn6: function () {
      if (this.btn6) {
        this.btn1 = false;
        this.btn2 = false;
        this.btn3 = false;
        this.btn4 = false;
        this.btn5 = false;
      }
    },
  },
};
</script>
<style scoped>
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: unset;
}
.title {
  color: #2405f2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>