<template>
  <div class="home">
    <BannerSection></BannerSection>
    <CoreValueSection></CoreValueSection>
    <!-- <ProjectSection></ProjectSection> -->
    <SolutionSection></SolutionSection>
    <CustomerSection></CustomerSection>
    <FAQSection></FAQSection>
    <ContactSection></ContactSection>
    <PostSection></PostSection>
  </div>
</template>
<script>
import BannerSection from "../components/home/BannerSection.vue";
import CoreValueSection from "../components/home/CoreValueSection.vue";
// import ProjectSection from "../components/home/ProjectSection.vue";
import SolutionSection from "../components/home/SolutionSection.vue";
import CustomerSection from "../components/home/CustomerSection.vue";
import FAQSection from "../components/home/FAQSection.vue";
import ContactSection from "../components/home/ContactSection.vue";
import PostSection from "../components/home/PostSection.vue";
export default {
  components: {
    BannerSection,
    CoreValueSection,
    // ProjectSection,
    SolutionSection,
    CustomerSection,
    FAQSection,
    ContactSection,
    PostSection,
  },
};
</script>
<style>
.f-color-back {
  color: #1b1c2b;
}
</style>