<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-navbar pt-3">
    <div class="container">
      <router-link class="link-text fw-bold" :to="{ name: 'Home' }">
        <img src="@/assets/logo.png" alt="" class="img-logo" />
      </router-link>
      <button
        v-on:click="navbar = !navbar"
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        v-bind:class="navbar == true ? 'd-block' : ''"
        class="collapse navbar-collapse flex-row-reverse"
        id="navbarNav"
      >
        <ul class="navbar-nav md-align-items-center">
          <li v-on:click="navbar = false" class="nav-item m-2">
            <router-link class="nav-link" :to="{ name: 'Home' }">
              Trang Chủ
            </router-link>
          </li>
          <li v-on:click="navbar = false" class="nav-item m-2">
            <a class="nav-link" href="../#giai-phap">Giải Pháp</a>
          </li>
          <li v-on:click="navbar = false" class="nav-item m-2">
            <router-link class="nav-link" :to="{ name: 'Post' }">
              Tin Tức
            </router-link>
          </li>
          <li v-on:click="navbar = false" class="nav-item m-2">
            <a class="nav-link" href="../#lien-he">Liên Hệ</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      navbar: false,
    };
  },
};
</script>
<style scoped>
a.nav-link.router-link-exact-active.router-link-active {
  color: #fff;
  font-weight: bold;
}
.bg-navbar {
  background-color: #1c1e53;
}
.img-logo {
  width: 68px;
}
@media (max-width: 992px) {
  #navbarNav {
    margin: 20px 10px 10px 0px;
    height: 100vh;
  }
}
</style>