<template>
  <div id="app">
    <Navbar />
    <transition>
      <router-view />
    </transition>
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/NavbarComponent.vue";
import Footer from "./components/FooterComponent.vue";
export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style>
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 17px;
}
.content {
  margin: 0px 10px 0px 10px;
}
.content img {
  width: 100%;
}

.enter {
  transform: translateX(100%);
}
.enter-to {
  transform: translateX(0);
}
.slide-enter-active {
  position: absolute;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.text-justify {
  text-align: justify;
}

.img-blog {
  height: 220px !important;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
