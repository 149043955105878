<template>
  <div v-if="list_post" class="container">
    <div class="row d-flex justify-content-center">
      <h2 class="fs-2 fw-bold text-center text-md-start">Tin tức</h2>
      <!-- list post -->
      <div
        v-for="post in list_post"
        :key="post.id"
        class="col-11 col-md my-1 mb-5"
      >
        <router-link
          class="link-text fw-bold"
          :to="{ name: 'PostDetail', params: { id: post.id } }"
        >
          <img v-if="post.featured_image" :src="post.featured_image" class="img-fluid img-post center" alt="Hình đại diện bài viết" />
        </router-link>

        <p class="my-2">
          {{ post.date | moment("DD/MM/YYYY") }}
        </p>
        <router-link
          class="link-text fw-bold"
          :to="{ name: 'PostDetail', params: { id: post.id } }"
        >
          <h2 class="fs-5 text-md-start fw-bold">
            {{ post.title.rendered }}
          </h2>
        </router-link>
        <p><div v-html="post.content.rendered.substring(0, 200)"></div>...</p>
        <router-link
          class="link-text fw-bold"
          :to="{ name: 'PostDetail', params: { id: post.id } }"
        >
          Xem thêm <i class="fa-solid fa-arrow-right-long mt-2"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import config from "../../config";
export default {
  data() {
    return {
      url_root: config.url_root_backend,
    };
  },
  computed: {
    ...mapGetters(["list_post"]),
  },
  methods: {
    ...mapActions(["api_get_three_post"]),
  },
  created: function () {
    this.api_get_three_post();
  },
};
</script>
<style scoped>
p {
  margin: 0px;
}
.link-text {
  text-decoration: none;
  color: #282938;
}
</style>