<template>
  <div class="container py-3" id="lien-he">
    <div class="row pb-5">
      <div
        class="col-md bg-img p-5"
        :style="{
          backgroundImage: `url(${require('@/assets/Group2551.png')})`,
        }"
      >
        <h1 class="fs-1 fw-bold title text-center text-md-start">
          Chuyển đổi số <br />
          Là một hành trình <br />
          Không phải điểm đến
        </h1>
        <p class="fs-6">
          Cùng chúng tôi khám phá các giá trị mới và tạo ra các mô hình kinh
          doanh mới, thông qua tận dụng những tiềm năng từ công nghệ.
        </p>
      </div>
      <div class="col bg-blue p-5">
        <h2 class="fs-3 text-center text-md-start fw-bold mb-4">
          LIÊN HỆ NGAY
        </h2>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            placeholder="Họ và tên"
            v-model="name"
          />
        </div>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            placeholder="Số điện thoại"
            v-model="phone"
          />
        </div>
        <div class="input-group mb-3">
          <textarea
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            placeholder="Nội dung"
            v-model="note"
          />
        </div>
        <div class="w-100">
          <pulse-loader
            :loading="loading"
            :color="'#ffffff'"
            class="text-center"
          ></pulse-loader>
          <p class="text-center">
            {{ error }}
          </p>
          <button
            type="button"
            class="btn btn-warning f-color-back rounded-pill w-100 fs-6"
            v-on:click="sendFrom()"
            v-if="loading == false"
          >
            Gửi Liên Hệ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../config";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  components: {
    PulseLoader,
  },
  data() {
    return {
      name: "",
      phone: "",
      note: "",
      error: "",
      loading: false,
    };
  },
  methods: {
    sendFrom() {
      if (this.name != "") {
        if (this.phone != "") {
          var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
          if (vnf_regex.test(this.phone)) {
            this.loading = true;
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
              formData: {
                name: this.name,
                phone: this.phone,
                note: this.note,
              },
            });
            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };
            fetch(config.form_api.send_form, requestOptions)
              .then((response) => response.text())
              .then((result) => {
                if (this.phone == JSON.parse(result).phone) {
                  this.error =
                    "Lưu thành công. Chúng tôi sẽ liên hệ lại trong thời gian sớm nhất!";
                  this.loading = false;
                  this.name = "";
                  this.phone = "";
                  this.note = "";
                } else {
                  this.error = "Lưu không thành công. Vui lòng tải lại trang!";
                  this.name = "";
                  this.phone = "";
                  this.note = "";
                }
              })
              .catch((error) => console.log("error", error));
          } else {
            this.error = "Số điện thoại không đúng";
          }
        } else {
          this.error = "Bạn chưa nhập số điện thoại";
        }
      } else {
        this.error = "Bạn chưa nhập tên";
      }
    },
  },
};
</script>
<style scoped>
.form-control {
  border: 1.5px solid #ffffff18;
  background-color: #1c1e53;
  border-radius: 8px;
  color: #ffffff;
}

.form-control:focus {
  border: 1.5px solid #ffffff18;
  background-color: #1c1e53;
  border-radius: 8px;
  color: #ffffff;
}
.link-text {
  color: #ffffff;
  text-decoration: none;
}
.btn-warning {
  background-color: #fcd980 !important;
  height: 50px;
  border: none !important;
  color: #1b1c2b;
}
.bg-img {
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-blue {
  background-color: #1c1e53;
  color: #ffffff;
}
</style>