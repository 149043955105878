<template>
  <div class="container mt-4">
    <img
      class="img-fluid mt-1"
      src="@/assets/people-working-in-front-of-computer-3182763.png"
      alt=""
    />
    <h1 class="fs-2 fw-bold text-center mt-4">Tin tức</h1>
    <!-- list post -->

    <div v-if="list_post" class="row d-flex justify-content-center mb-4">
      <div
        v-for="post in list_post"
        :key="post.id"
        class="col-11 col-md-6 col-lg-4 my-5"
      >
        <router-link
          class="link-text fw-bold"
          :to="{ name: 'PostDetail', params: { id: post.id } }"
        >
          <img v-if="post.featured_image" :src="post.featured_image" class="img-fluid img-post center" alt="Hình đại diện bài viết" />
        </router-link>
        <p class="mt-4">
          {{ post.date | moment("DD/MM/YYYY") }}
        </p>
        <router-link
          class="link-text fw-bold"
          :to="{ name: 'PostDetail', params: { id: post.id } }"
        >
          <h2 class="fs-5 fw-bold mt-1">{{ post.title.rendered }}</h2>
        </router-link>
        <p><div v-html="post.content.rendered.substring(0, 200)"></div>...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "../../config";
export default {
  data() {
    return {
      localListPost: [], 
    };
  },
  computed: {
    ...mapGetters(["list_post"]),
  },
  methods: {
    ...mapActions(["api_get_all_post"]),
  },
  created: function () {
    this.api_get_all_post();
  },
};
</script>

<style scoped>
.link-text {
  text-decoration: none;
  color: #282938;
}
p {
  margin: 0px;
}
</style>