export default {
    post_api: {
        get_all_post: 'https://cms.bitdigi.vn/wp-json/wp/v2/posts',
        get_three_post: 'https://cms.bitdigi.vn/wp-json/wp/v2/posts?per_page=3&orderby=date&order=desc',
        get_post_detail: 'https://cms.bitdigi.vn/wp-json/wp/v2/posts/'
    },
    form_api: {
        send_form: "https://api.bitdigi.vn/api/ezforms/submit/"
    },
    url_root_backend: 'https://cms.bitdigi.vn'
}