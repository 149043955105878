<template>
  <div class="section5 py-3">
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-3 px-3">
          <h2 class="fs-2 fw-bold title text-center text-md-start">
            Cảm nhận của khách hàng
          </h2>
          <p class="text-center text-md-start">
            Chúng tôi trân trọng từng góp ý quý báo của quý khách!
          </p>
        </div>
        <div class="col-md-9 px-4 pt-md-0 pt-3">
          <agile :options="VueAgilemyOptions">
            <div class="slide">
              <h3 class="text-center text-md-start fs-5">
                "Chỉ cần kết nối với BitDigi các khách hàng tiềm năng của doanh
                nghiệp đều được chăm sóc và chuyển đổi..."
              </h3>
              <div class="d-flex mx-3 mt-4">
                <img
                  src="@/assets/kimhoan.jpg"
                  alt=""
                  width="62px"
                  height="62px"
                  style="border-radius: 100%"
                />
                <p class="mx-3 my-0 fw-bold">
                  Kim Hoàn <br /><span class="fw-normal"
                    >Sale manager Kojiesan.vn</span
                  >
                </p>
              </div>
            </div>
            <div class="slide">
              <h3 class="text-center text-md-start fs-5">
                "BitDigi giúp thay đổi và triển khai thành công mô hình, quy
                trình bán hàng 4.0 cho chúng tôi..."
              </h3>
              <div class="d-flex mx-3 mt-4">
                <img
                  src="@/assets/thaiduy.png"
                  alt=""
                  width="62px"
                  height="62px"
                  style="border-radius: 100%"
                />
                <p class="mx-3 my-0 fw-bold">
                  Thái Duy <br /><span class="fw-normal">CEO Store285.com</span>
                </p>
              </div>
            </div>
            <div class="slide">
              <h3 class="text-center text-md-start fs-5">
                "Tôi hài lòng với phong cách làm việc tận tâm, hết lòng phục vụ
                khách hàng của BitDigi..."
              </h3>
              <div class="d-flex mx-3 mt-4">
                <img
                  src="@/assets/ngocdinh.jpg"
                  alt=""
                  width="62px"
                  height="62px"
                  style="border-radius: 100%"
                />
                <p class="mx-3 my-0 fw-bold">
                  Ngọc Định <br /><span class="fw-normal">CEO VNetMedia</span>
                </p>
              </div>
            </div>
            <template slot="prevButton">
              <i class="fa-solid fa-angle-left fs-4"></i>
            </template>
            <template slot="nextButton">
              <i class="fa-solid fa-angle-right fs-4"></i>
            </template>
          </agile>
        </div>
      </div>
    </div>
  </div>
</template>
       
<script>
import { VueAgile } from "vue-agile";
export default {
  components: {
    agile: VueAgile,
  },
  data() {
    return {
      VueAgilemyOptions: {
        navButtons: true,
        responsive: [
          {
            breakpoint: 500,
            settings: {
              dots: false,
            },
          },
        ],
      },
    };
  },
};
</script>
<style>
.agile__dots {
  display: none;
}
.agile__actions {
  justify-content: flex-end;
}
.agile__nav-button {
  border: unset;
  margin: 10px;
  background-color: unset;
}
.section5 {
  background-color: #f4f6fc91;
}
</style>